.adminPanel {
  position: fixed;
  top: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#fff, 0.8);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 16px;

  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    h1 {
      text-align: center;
      color: #fff;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 0 20px;
    overflow-x: hidden;
    overflow-y: scroll;

    table {
      width: 100%;
      max-width: 90vw;
      row-gap: 8px;
      margin: 40px;
      vertical-align: top;
      tr {
        th {
          color: #000;
        }
        td {
          vertical-align: top;
          padding: 4px 8px;
          .readonly {
            background: transparent;
            border: none;
          }
        }
      }
    }
    .status {
      //position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0px;
    }
  }

  input,
  textarea {
    display: inline-flex;
    padding: 4px 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 2px;
    &:focus {
      border-color: #6babe4;
      outline: none;
    }
    &.button {
      background: #6babe4;
      color: #fff;
      border: none;
      font-size: 24px;
    }
  }
  .tabBar {
    display: flex;
    flex-direction: row;
    .tab {
      display: flex;
      appearance: none;
      background: #fff;
      border: none;
      padding: 8px 16px;
      border-bottom: 4px solid #fff;
      &.active {
        border-bottom-color: #6babe4;
      }
    }
  }
  .events {
    display: flex;
    flex-direction: column;
    gap: 32px;
    h1 {
      color: #000;
      text-align: left;
      width: 100%;
    }
    .eventsList {
      display: flex;
      flex-direction: column;
      gap: 32px;
      .eventsList_Item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .label_combo {
          display: flex;
          flex-direction: row;
          label {
            color: #000;
            width: 100px;
          }
        }
      }
    }
  }

  .fullWidth {
    width: 100%;
  }
}
