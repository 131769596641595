@import "_vars";

.AppContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  max-width: 1680px;
  margin: 0 auto;
  width: 100%;
}

.de {
  .en {
    display: none !important;
  }
}

.en {
  .de {
    display: none !important;
  }
}

section {
  picture {
    display: flex;
    width: 100%;
    min-height: 80vh;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 600px) {
      min-height: 60vh;
    }
    img {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      position: absolute;
    }
  }
}

.mt {
  margin-top: 100px;
}

.presse {
  margin: 40px;
  .slick-dots li button:before {
    color: #fff;
  }
  .slick-slide {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  blockquote {
    font-family: $headlines;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%; /* 36px */
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    .author {
      display: inline-flex;
      width: 100%;
      font-family: $body;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: var(--blue-30, #020a10);
  @media screen and (max-width: 600px) {
    //flex-direction: column;
    gap: 8px;
  }
  #logo {
    font-size: 24px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 40px;
    &.links {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }
  a {
    text-decoration: none;
  }
  .nextShow {
    display: inline-flex;
    gap: 8px;
    .title {
      color: var(--red-40, #f2513f);
    }
  }
}

.hero {
  .text {
    .headline {
      display: flex;
      gap: 24px;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      gap: 48px;
      .button {
        padding: 4px 16px;
        border: 1px solid #fff;
        display: inline-flex;
        gap: 16px;
        text-decoration: none;
        align-items: center;
        border-radius: 4px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        &:after {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          background: url("./icons/arrow_right.svg") center center no-repeat;
        }
      }
    }
  }
  .image {
    position: relative;
    overflow: hidden;
    video {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.contentblock {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 16px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  .text,
  .image {
    max-width: calc(50% - 8px);
    flex-grow: 1;
    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
  }
  .image {
    min-height: 60vh;
    &.w-1-2 {
      width: 100%;
      max-width: calc(50% - 8px);
      flex-grow: 1;
      @media screen and (max-width: 600px) {
        max-width: 100%;
        grid-auto-columns: auto;
      }
    }
    @media screen and (max-width: 600px) {
      order: 0;
    }
  }
  .text {
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    width: calc(50% - 8px);
    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 16px;
      order: 5;
    }
  }
  &.team {
    .text {
      padding: 40px;
      dl {
        display: grid;
        //grid-template-rows: 2fr auto;
        grid-template-columns: 1fr 2fr;
        grid-gap: 24px;
        dt {
          font-style: italic;
        }
        dd {
          margin: 0;
        }
      }
    }
  }
  &.kontakt {
    .contactBlock {
      width: calc(50% - 8px);
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      picture {
        min-height: 30%;
        height: 30%;
      }
      .text {
        width: 100%;
        max-width: 100%;
        @media screen and (max-width: 600px) {
          padding: 16px;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 32px;
          li {
            a {
              display: inline-flex;
              align-items: center;
              text-decoration: none;
              gap: 16px;
            }
          }
        }
      }
    }
  }
  &.produktionen {
    //max-height: 500px;
    .text {
      a {
        picture {
          width: 152px;
          height: 120px;
          min-height: auto;
        }
      }
    }
    .image {
      min-height: 500px;
      @media screen and (max-width: 600px) {
        min-height: 300px;
      }
      picture {
        min-height: auto;
        @media screen and (max-width: 600px) {
          min-height: 300px;
        }
      }
    }
  }
  &.impressionen {
    @media screen and (max-width: 600px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }
    .image {
      min-height: 50vh;
      @media screen and (max-width: 600px) {
        //max-width: calc(50% - 8px);
        min-height: 20vh;
        aspect-ratio: 1 /1;
      }
      picture {
        min-height: 50vh;
        @media screen and (max-width: 600px) {
          min-height: 20vh;
        }
      }
    }
  }
  &.logos {
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 40px;
    @media screen and (max-width: 600px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }
    .logo {
      width: 100%;
      min-height: 100px;
      align-items: center;
      picture {
        width: 100%;
        height: 100px;
        max-height: 100px;
        min-height: auto;
        object-fit: contain;
        img {
          position: absolute;
          object-fit: contain;
          object-position: center center;
          width: 100%;
          height: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 0px;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    .text {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

.showHeader {
  a {
    text-decoration: none;
    margin-right: 20px;
  }
  .faded {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
}

.events {
  padding: 64px 0;
  .slick-slide {
    padding: 16px;
  }
  .slick-dots li button:before {
    color: #fff;
  }
  .slick-track {
    margin-left: 0;
  }
}

.event {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  margin-top: 24px;
  margin-bottom: 24px;
  background: #fff;
  max-width: 100%;
  .date {
    width: 64px;
    height: 64px;
    background: #fff;
    border-radius: 4px;
    font-family: $headlines;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -24px;
    right: 16px;
    .month {
      background: var(--red-30, #9a041e);
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      //letter-spacing: 3.78px;
      letter-spacing: 1.78px;
      text-transform: uppercase;
      padding: 2px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      height: 24px;
    }
    .day {
      color: var(--blue-30, #020a10);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  .image {
    img {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
  .content {
    color: var(--blue-30, #020a10);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    h5 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      opacity: 0.5;
    }
    a {
      color: var(--red-30, #9a041e);
      text-decoration: none;
    }
    .links {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      gap: 4px;
    }
  }
}
