@import url(https://fonts.bunny.net/css?family=barlow:400,400i,500|inter:400,400i);
@import "_vars";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--blue-30, #020a10);
  font-family: $body;
  color: $textColor;
  box-sizing: border-box;
  * {
    box-sizing: inherit;
  }
  a {
    color: $textColor;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headlines;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h1 {
  text-align: right;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 72px */
  @media screen and (max-width: 400px) {
    text-align: left;
  }
}
h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 60px */
  margin-top: 8px;
}
h3 {
  text-transform: uppercase;
  font-family: $body;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
}
